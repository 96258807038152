import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {combineLatest} from 'rxjs';
import {JobService} from '../../services/job.service';
import {Job} from '../../classes/job.class';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ClassroomService} from '../../services/classroom/classroom.service';
import {Classroom} from '../../classes/classroom.class';
import {LocalStorage} from '../../storage.class';
import {startWith} from 'rxjs/operators';
import {DateNLFormatAdapter} from '../../date-adapters/date-nlformat-adapter';
import {DateAdapter} from '@angular/material/core';
import {Location} from '@angular/common';
import {Image} from '../../classes/image.class';
import {Routenames} from '../../route-names.enum';
import {Utils} from '../../utils.class';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {AuthorisationService} from '../../services/auth/authorisation.service';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {Document} from '../../classes/document.class';

@Component({
    selector: 'app-job-edit',
    templateUrl: './job-edit.component.html',
    styleUrls: ['./job-edit.component.scss'],
    providers: [
        {provide: DateAdapter, useClass: DateNLFormatAdapter}
    ]
})
export class JobEditComponent extends CodaltComponent implements OnInit {

    reserve = false;
    saving = false;
    form: UntypedFormGroup;
    classrooms: Classroom[];
    job: Job;

    constructor(private jobService: JobService,
                private classroomService: ClassroomService,
                private location: Location,
                private router: Router,
                private dialog: ConfirmDialogService,
                private route: ActivatedRoute) {
        super();
    }

    reorderImages(event: CdkDragDrop<Image[]>) {
        const movedImage = this.job.images[event.previousIndex];
        this.job.images.splice(event.previousIndex, 1);
        this.job.images.splice(event.currentIndex, 0, movedImage);
        this.jobService.sortImages(this.job).subscribe(() => {
        });
    }

    ngOnInit(): void {
        this.subscriptions.add(combineLatest([LocalStorage.schoolChange.pipe(startWith(LocalStorage.school)), this.route.params]).subscribe(([school, params]) => {
            if (LocalStorage.school) {
                this.getJob(params['id']);
            }
        }));
    }

    delete() {
        this.dialog.confirm('Verwijderen',
            `Weet je zeker dat je deze vacature wilt verwijderen?`, 'Ja', 'Nee').then(() => {
            this.jobService.delete(this.job.id).subscribe(() => {
                this.router.navigateByUrl(Routenames.jobs);
            });
        }, () => {
        });
    }

    save() {
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            this.saving = true;
            this.jobService.save(this.form.value).subscribe(() => {
                this.saving = false;
                this.form.markAsPristine();
                this.router.navigateByUrl(Routenames.jobs);
            }, () => {
                this.saving = false;
            });
        }
    }

    uploadedFiles(files) {
        if (!this.job.images) {
            this.job.images = [];
        }
        files.file.forEach(file => {
            this.jobService.addImage(this.job.id, file.filename).subscribe(img => {
                const loading = this.job.images.find(p => !p.id);
                if (loading) {
                    img.data.thumb = loading.thumb;
                    Object.assign(loading, img.data);
                } else {
                    this.job.images.push(img.data);
                }
            });
        });
    }

    uploadFiles(files?: File[]) {
        Array.from(files).forEach(file => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (!this.job.images) {
                    this.job.images = [];
                }
                this.job.images.push({
                    thumb: reader.result.toString()
                } as Image);
            };
        });
    }

    deleteImage(image: Image) {
        this.jobService.rmImage(image.id).subscribe(() => {
            const index = this.job.images.indexOf(image);
            this.job.images.splice(index, 1);
        });
    }

    uploadedMainImage(file) {
        this.form.get('image').setValue(file ? file.file.filename : null);
    }

    uploadMainImage(files) {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            this.job.image_thumb = reader.result.toString();
        };
        this.form.get('image').setValue(null);
    }

    uploadedDocuments(files) {
        if (!this.job.documents) {
            this.job.documents = [];
        }
        files.file.forEach(file => {
            this.jobService.addDocument(this.job.id, file.filename).subscribe(img => {
                const loading = this.job.documents.find(p => !p.id);
                if (loading) {
                    img.data.thumb = loading.thumb;
                    Object.assign(loading, img.data);
                } else {
                    this.job.documents.push(img.data);
                }
            });
        });
    }

    reorderDocuments(event: CdkDragDrop<Document[]>) {
        const movedImage = this.job.documents[event.previousIndex];
        this.job.documents.splice(event.previousIndex, 1);
        this.job.documents.splice(event.currentIndex, 0, movedImage);
        this.jobService.sortDocuments(this.job).subscribe(() => {
        });
    }

    deleteDocument(document: Document) {
        this.jobService.rmDocument(document.id).subscribe(() => {
            const index = this.job.documents.indexOf(document);
            this.job.documents.splice(index, 1);
        });
    }

    private getJob(slug) {
        this.reserve = slug === 'nieuw';
        const classrooms$ = this.classroomService.getClassrooms();
        const job$ = this.reserve ? this.jobService.reserve() : this.jobService.get(slug);
        combineLatest([classrooms$, job$]).subscribe(([classrooms, job]) => {
            this.classrooms = classrooms.data;
            this.job = job.data;
            this.generateForm(this.job);
            if (!this.reserve) {
                this.location.replaceState(`${Routenames.jobs}/${job.data.slug}`);
            }
        }, error => {
            this.dialog.confirm('Fout', error.message, 'Terug naar overzicht', null).then(() => {
                this.router.navigateByUrl(Routenames.jobs);
            });
        });
    }

    private generateForm(job: Job) {
        let images = job.images ? job.images.map(img => img.id) : [];
        const fcAccessLevel = new UntypedFormControl(job.access_level ? job.access_level : AuthorisationService.hasFeature('editJob') ? 'PUBLIC' : 'LOGGEDIN');
        this.form = new UntypedFormGroup({
            id: new UntypedFormControl(job.id),
            title: new UntypedFormControl(job.title, Validators.required),
            content: new UntypedFormControl(job.content),
            publish_date: new UntypedFormControl(job.publish_date ? job.publish_date : new Date()),
            enddate: new UntypedFormControl(job.enddate),
            images: new UntypedFormControl(images),
            access_level: fcAccessLevel,
            image: new UntypedFormControl(job.image)
        });
    }
}

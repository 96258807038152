<div class="items">

    <virtual-scroller #virtualScroll (vsEnd)="scroll($event)" [items]="jobs" class="item-list">
        <div *ngFor="let job of virtualScroll.viewPortItems" class="item" routerLink="/{{Routenames.jobs}}/{{job.slug}}">
            <app-codalt-image [path]="job.id + '/' + job.image"
                              [thumb]="job.image_thumb"
                              class="detail__image"
                              secureType="job">
            </app-codalt-image>
            <div [class.alert]="job.alert" class="detail__block">
                <h3 class="item__title">{{job.title}}</h3>
                <div class="details">
                    <time>
                        {{job.publish_date | date:'EEEE d MMMM yyyy'}}
                        <ng-container *ngIf="job.enddate">
                            - {{job.enddate | date:'EEEE d MMMM yyyy'}}
                        </ng-container>
                    </time>
                </div>
            </div>
        </div>
        <div class="item-spacer"></div>
        <h2 class="title">Vacatures</h2>
    </virtual-scroller>
    <div class="floating-buttons">
        <button *ngIf="AuthorisationService.hasFeature('editJob')" color="primary" mat-fab
                routerLink="/{{Routenames.jobs}}/nieuw">
            <mat-icon class="fas fa-plus"></mat-icon>
        </button>
    </div>
    <div *ngIf="jobs.length === 0 && !loading" class="not-found">
        Er zijn nog geen vacatures geplaatst
    </div>
</div>

<div class="container bg-white mt-2 item-edit">
    <div>
        <h2 class="news__title">Vacature {{reserve ? 'toevoegen' : 'wijzigen'}}</h2>
    </div>
    <div *ngIf="form">
        <form [formGroup]="form">
            <div (click)="fileManagerMain.chooseSource()"
                 class="main-image">
                <app-codalt-image [path]="form.value.id+'/'+form.controls['image'].value"
                                  [thumb]="job.image_thumb"
                                  secureType="job">
                </app-codalt-image>
                <file-manager #fileManagerMain
                              (callback)="uploadedMainImage($event)"
                              (loading)="uploadMainImage($event)"
                              [class.text-white]="isCordova && form.controls['image'].value"
                              [currentFile]="form.controls['image'].value"
                              [directUpload]="true"
                              [path]="form.value.id"
                              [remove]="true"
                              icon="{{isCordova ? 'fas fa-image' : null}}"
                              secure="job">
                </file-manager>
                <div class="info"><i class="fas fa-alert"></i> Let op: plaats hier een algemene foto zonder tekst</div>
            </div>
            <mat-form-field class="w-100 mt-3">
                <mat-label>Titel</mat-label>
                <input formControlName="title" matInput type="text">
                <mat-error [control]="form.get('title')"></mat-error>
            </mat-form-field>
            <div class="row">
                <div class="col">
                    <mat-form-field (click)="picker.open()" class="col-12">
                        <mat-label>Publicatiedatum</mat-label>
                        <input [matDatepicker]="picker" formControlName="publish_date" matInput>
                        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error [control]="form.get('publish_date')"></mat-error>
                    </mat-form-field>
                    <mat-form-field (click)="pickerEnd.open()" class="col-12">
                        <mat-label>Sluitingsdatum</mat-label>
                        <input [matDatepicker]="pickerEnd" formControlName="enddate" matInput>
                        <mat-datepicker-toggle [for]="pickerEnd" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #pickerEnd></mat-datepicker>
                        <mat-error [control]="form.get('enddate')"></mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div>
                <b>Vacaturetekst: </b>
                <simple-tiny [content]="form.get('content')"
                             elementId="content"
                             ngDefaultControl
                             propertyName="value"
                ></simple-tiny>
                <mat-error [control]="form.get('content')"></mat-error>
            </div>
            <div class="mt-2 mb-2">
                <b>Zichtbaarheid: </b>
                <div class="mt-2">
                    <mat-radio-group formControlName="access_level">
                        <mat-radio-button *ngIf="AuthorisationService.hasFeature('editJob')" class="mr-2" value="PUBLIC">Website &
                            App
                        </mat-radio-button>
                        <mat-radio-button class="mr-2" value="LOGGEDIN">App (ouders en medewerkers)</mat-radio-button>
                        <mat-radio-button class="mr-2" value="EMPLOYEE">App alleen medewerkers</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="pb-3">
                <b>Afbeeldingen</b>
                <div (cdkDropListDropped)="reorderImages($event)" cdkDropList class="images">
                    <div *ngFor="let image of job.images" cdkDrag class="image-edit">
                        <app-codalt-image [path]="job.id + '/' + image.path"
                                          [thumb]="image.thumb"
                                          secureType="job">
                        </app-codalt-image>

                        <button (click)="deleteImage(image)" color="primary" mat-mini-fab>
                            <mat-icon class="fas fa-trash"></mat-icon>
                        </button>
                        <div cdkDragHandle class="drag-handle">
                            <i class="fa fa-arrows-alt-v"></i>
                        </div>
                    </div>
                </div>
                <div class="mt-2 mb-2">
                    <file-manager
                        (callback)="uploadedFiles($event)"
                        (loading)="uploadFiles($event)"
                        [directUpload]="true"
                        [multiple]="true"
                        [path]="form.value.id"
                        secure="job">
                    </file-manager>
                </div>
            </div>
            <div class="pb-3">
                <b>PDF documenten</b>
                <table (cdkDropListDropped)="reorderDocuments($event)" cdkDropList>
                    <tr *ngFor="let document of job.documents" cdkDrag>
                        <td cdkDragHandle class="curmove">
                            <i class="fas fa-file-pdf-o"></i>
                        </td>
                        <td cdkDragHandle class="curmove">
                            {{document.path}}
                        </td>
                        <td>
                            <button (click)="deleteDocument(document)" color="primary" mat-icon-button>
                                <mat-icon class="fas fa-trash"></mat-icon>
                            </button>
                        </td>
                    </tr>
                </table>
                <div class="mt-2 mb-2">
                    <file-manager
                        [hideCamera]="true"
                        (callback)="uploadedDocuments($event)"
                        [directUpload]="true"
                        [multiple]="true"
                        [path]="form.value.id"
                        secure="jobDocument">
                    </file-manager>
                </div>
            </div>
        </form>

    </div>
    <div class="floating-buttons">
        <button (click)="save()" [disabled]="saving" color="primary" mat-fab>
            <mat-icon *ngIf="!saving" class="fas fa-save"></mat-icon>
            <mat-icon *ngIf="saving" class="fas fa-circle-notch fa-spin"></mat-icon>
        </button>
        <button (click)="delete()" color="primary" mat-fab>
            <mat-icon class="fas fa-trash"></mat-icon>
        </button>
    </div>
</div>
